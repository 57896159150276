// AuthProvider.js
import React, { createContext, useState, useEffect } from "react";
import supabase from "../supabaseClient"; // Ensure correct path

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const {
          data: { session },
          error,
        } = await supabase.auth.getSession();

        if (error) {
          console.error("Error getting session:", error.message);
        }

        setSession(session);

        if (session) {
          await fetchUserProfile(session);
        } else {
          setProfile(null);
        }
      } catch (err) {
        console.error("Error in initializeAuth:", err);
      } finally {
        setLoading(false); // Set loading to false after initialization
      }
    };

    initializeAuth();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
      if (session) {
        fetchUserProfile(session);
      } else {
        setProfile(null);
      }
      // Do NOT manage 'loading' here
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const fetchUserProfile = async (session) => {
    try {
      const { data: profileData, error } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session.user.id)
        .single();

      if (error && error.code === "PGRST116") {
        // Profile doesn't exist; create one
        const { error: insertError } = await supabase.from("profiles").insert({
          id: session.user.id,
          email: session.user.email,
        });
        if (insertError) {
          console.error("Error creating profile:", insertError.message);
        } else {
          setProfile({ id: session.user.id, email: session.user.email });
        }
      } else if (error) {
        console.error("Error fetching profile:", error.message);
      } else {
        setProfile(profileData);
      }
    } catch (err) {
      console.error("Error in fetchUserProfile:", err);
    }
  };

  const refreshProfile = async () => {
    if (session) {
      await fetchUserProfile(session);
    }
  };

  return (
    <AuthContext.Provider value={{ session, profile, loading, refreshProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
