// Header.js
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import scanToScheduleLogo from "../assets/scan-to-schedule-logo.png";
import { MenuIcon } from "@heroicons/react/outline";

const Header = ({ handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Determine whether to show the 'Back to Dashboard' button
  const showBackButton =
    location.pathname !== "/dashboard" && location.pathname !== "/";

  return (
    <header className="flex items-center justify-between px-6 py-4 bg-white border-b">
      {/* Logo and Title */}
      <div
        onClick={() => navigate("/")}
        className="flex items-center cursor-pointer"
      >
        <img
          src={scanToScheduleLogo}
          alt="Scan to Schedule Logo"
          className="w-8 h-8 mr-2"
        />
        <span className="text-xl font-medium text-gray-900">
          ScanToSchedule
        </span>
      </div>

      {/* Hamburger Menu for mobile */}
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="focus:outline-none md:hidden"
      >
        <MenuIcon className="h-6 w-6 text-gray-700" />
      </button>

      {/* Menu items for desktop */}
      <div className="hidden md:flex items-center space-x-4">
        {showBackButton && (
          <button
            onClick={() => navigate("/dashboard")}
            className="px-4 py-2 rounded border border-gray-300 hover:bg-gray-100"
          >
            Back to Dashboard
          </button>
        )}

        {/* Featurebase Feedback Link */}
        <a
          href="https://dbw.featurebase.app"
          target="_blank"
          rel="noopener noreferrer"
          className="px-4 py-2 rounded border border-gray-300 hover:bg-gray-100"
        >
          Feedback
        </a>

        <button
          onClick={handleLogout}
          className="px-4 py-2 rounded border border-gray-300 text-red-600 hover:bg-gray-100"
        >
          Logout
        </button>
      </div>

      {/* Side Menu Overlay and Menu */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          {/* Side Menu */}
          <div className="fixed top-0 right-0 w-64 h-full bg-white border-l border-gray-200 z-20 md:hidden">
            <div className="p-4">
              {/* Close Button */}
              <button
                onClick={() => setIsMenuOpen(false)}
                className="focus:outline-none mb-4"
              >
                <svg
                  className="h-6 w-6 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              {/* Back Button */}
              {showBackButton && (
                <button
                  onClick={() => navigate("/dashboard")}
                  className="mt-4 w-full px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Back to Dashboard
                </button>
              )}

              {/* Featurebase Feedback Link */}
              <a
                href="https://dbw.featurebase.app"
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 w-full block text-center px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
              >
                Feedback
              </a>

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="mt-4 w-full px-6 py-3 rounded border border-gray-300 text-red-600 hover:bg-gray-100"
              >
                Logout
              </button>
            </div>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
