// components/ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthProvider";

const ProtectedRoute = ({ children }) => {
  const { session, loading } = useContext(AuthContext);

  if (loading) {
    // Render a loading indicator while authentication is being checked
    return <div>Loading...</div>;
  }

  if (!session) {
    // If not authenticated, redirect to the login page
    return <Navigate to="/" replace />;
  }

  // If authenticated, render the protected component
  return children;
};

export default ProtectedRoute;
