// PricingContext.js

import React, { createContext, useContext, useState } from "react";
import PricingModal from "../modals/PricingModal";

export const ModalContext = createContext();

export const useModalContext = () => {
  return useContext(ModalContext);
};

export const PricingContext = ({ children }) => {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  const openPricingModal = () => {
    setIsPricingModalOpen(true);
  };

  const closePricingModal = () => {
    setIsPricingModalOpen(false);
  };

  return (
    <ModalContext.Provider value={{ openPricingModal, closePricingModal }}>
      {children}
      {isPricingModalOpen && <PricingModal />}
    </ModalContext.Provider>
  );
};
