// PricingModal.js

import React, { useEffect, useContext } from "react";
import FocusLock from "react-focus-lock";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useModalContext } from "../contexts/PricingContext";
import { AuthContext } from "../contexts/AuthProvider";

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PricingModal = () => {
  const { closePricingModal } = useModalContext();
  const { session, handleGoogleLogin } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCheckout = async (priceId) => {
    if (!session) {
      // User is not logged in
      const redirectTo = `${window.location.origin}${location.pathname}?priceId=${priceId}`;
      await handleGoogleLogin(redirectTo);
      return;
    }

    // User is logged in, proceed with checkout
    const stripe = await stripePromise;
    const accessToken = session.access_token;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stripe-webhook`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ priceId }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Redirect to Stripe Checkout
        window.location.href = data.url;
      } else {
        throw new Error(data.error || "Failed to create checkout session.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  // Handle Post-Login Checkout
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const priceId = params.get("priceId");

    if (session && priceId) {
      // Remove priceId from URL to prevent repeated execution
      navigate(location.pathname, { replace: true });
      handleCheckout(priceId);
    }
  }, [session, location.search]);

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50"
      role="dialog"
      aria-modal="true"
    >
      <FocusLock>
        <div
          className="bg-white rounded-lg shadow-lg max-w-2xl w-full mx-4 sm:mx-6 md:mx-auto p-6 relative overflow-y-auto"
          style={{ maxHeight: "90vh" }} // Add maxHeight to limit modal height
        >
          {/* Close button */}
          <button
            onClick={closePricingModal}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close modal"
          >
            {/* Close icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {/* Modal Content */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">
            Upgrade Your Plan
          </h2>
          <div className="space-y-6">
            {/* Monthly Plan */}
            <div className="border border-gray-300 rounded-md p-6">
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                Monthly Plan
              </h3>
              <p className="text-gray-700 mb-4">
                $5<span className="text-sm">/month</span>
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-6 space-y-1">
                <li>Unlimited access to scheduling features</li>
                <li>Sync with Google Calendar</li>
                <li>Basic email support</li>
              </ul>
              <button
                onClick={() =>
                  handleCheckout(process.env.REACT_APP_MONTHLY_SUBSCRIPTION)
                }
                className="w-full py-2 px-4 border border-gray-300 text-gray-800 rounded hover:bg-gray-100 focus:outline-none"
              >
                Choose Monthly Plan
              </button>
            </div>

            {/* Annual Plan */}
            <div className="border border-gray-300 rounded-md p-6">
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                Annual Plan
              </h3>
              <p className="text-gray-700 mb-4">
                $40<span className="text-sm">/year</span>
              </p>
              <ul className="list-disc list-inside text-gray-700 mb-6 space-y-1">
                <li>Unlimited access to scheduling features</li>
                <li>Sync with Google Calendar</li>
                <li>Priority email support</li>
                <li>Save 33% with the annual plan</li>
              </ul>
              <button
                onClick={() =>
                  handleCheckout(process.env.REACT_APP_ANNUAL_SUBSCRIPTION)
                }
                className="w-full py-2 px-4 border border-gray-300 text-gray-800 rounded hover:bg-gray-100 focus:outline-none"
              >
                Choose Annual Plan
              </button>
            </div>
          </div>
        </div>
      </FocusLock>
    </div>
  );
};

export default PricingModal;
