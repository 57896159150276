// Cancel.js
import React from "react";

const Cancel = ({ navigate }) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Subscription Canceled</h1>
        <p className="text-gray-700">
          Your subscription was not completed. Feel free to try again.
        </p>
        <button
          onClick={() => navigate("/")}
          className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100 transition"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Cancel;
