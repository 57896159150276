import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "./components/Header";
import ScheduleModal from "./components/scheduleModal";
import { AuthContext } from "./contexts/AuthProvider";
import { toast } from "react-toastify";
import { useModalContext } from "./contexts/PricingContext";

const Dashboard = ({ handleLogout }) => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoadingEvents, setIsLoadingEvents] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [highlightedEventIds, setHighlightedEventIds] = useState([]);
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [confirmDeleteEventId, setConfirmDeleteEventId] = useState(null);

  const { session, profile, refreshProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const { openPricingModal } = useModalContext();

  useEffect(() => {
    fetchUpcomingEvents();
  }, [location.state?.newEvents]);

  useEffect(() => {
    refreshProfile();
  }, [location]);

  const fetchUpcomingEvents = async () => {
    if (!session) {
      // If session isn't ready yet, wait or show a loading indicator
      return;
    }
    setIsLoadingEvents(true);
    setError(null);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/upcoming-events`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch upcoming events");
      }

      const data = await response.json();
      setUpcomingEvents(data.events);

      if (location.state && location.state.newEvents) {
        const newEventLinks = location.state.newEvents.map(
          (event) => event.htmlLink
        );
        const newIds = data.events
          .filter((event) => newEventLinks.includes(event.htmlLink))
          .map((event) => event.id);
        setHighlightedEventIds(newIds);

        navigate(location.pathname, { replace: true, state: {} });
      }
    } catch (error) {
      console.error("Error fetching upcoming events:", error);
      setError(error.message);
    } finally {
      setIsLoadingEvents(false);
    }
  };

  const handleUpgrade = () => {
    openPricingModal();
  };

  // Clear highlighted events after 5 seconds
  useEffect(() => {
    if (highlightedEventIds.length > 0) {
      const timer = setTimeout(() => {
        setHighlightedEventIds([]);
      }, 5000); // 5 seconds

      return () => clearTimeout(timer);
    }
  }, [highlightedEventIds]);

  // Helper function to format start and end times
  const formatTime = (start, end) => {
    const eventStart = new Date(start);
    const eventEnd = new Date(end);
    const startTime = eventStart.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    const endTime = eventEnd.toLocaleTimeString(undefined, {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });
    return `${startTime} - ${endTime}`;
  };

  // Helper function to format event time with day label
  const formatEventTime = (start, end) => {
    const now = new Date();
    const eventStart = new Date(start);
    const eventEnd = new Date(end);

    const isToday =
      eventStart.getDate() === now.getDate() &&
      eventStart.getMonth() === now.getMonth() &&
      eventStart.getFullYear() === now.getFullYear();

    const isTomorrow =
      eventStart.getDate() === now.getDate() + 1 &&
      eventStart.getMonth() === now.getMonth() &&
      eventStart.getFullYear() === now.getFullYear();

    let dayLabel = "";
    if (isToday) {
      dayLabel = "Today";
    } else if (isTomorrow) {
      dayLabel = "Tomorrow";
    } else {
      dayLabel = eventStart.toLocaleDateString(undefined, {
        weekday: "long", // Full day name, e.g., "Monday"
        month: "short", // Short month name, e.g., "Nov"
        day: "numeric", // Numeric day, e.g., "2"
      });
    }

    const time = formatTime(start, end);

    return `${dayLabel} at ${time}`;
  };

  // Helper function to group events by day label
  const groupEventsByDate = (events) => {
    return events.reduce((groups, event) => {
      const dayLabel = formatEventTime(
        event.start.dateTime,
        event.end.dateTime
      ).split(" at ")[0];
      if (!groups[dayLabel]) {
        groups[dayLabel] = [];
      }
      groups[dayLabel].push(event);
      return groups;
    }, {});
  };

  // Define groupedEvents
  const groupedEvents = groupEventsByDate(upcomingEvents);

  // Handle "Upload Photo" button click
  const handleUploadPhotoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the hidden file input
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      const reader = new FileReader();
      reader.onload = function (e) {
        const imageDataUrl = e.target.result;
        processUploadedPhoto(imageDataUrl);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  // Process uploaded photo
  const processUploadedPhoto = async (imageDataUrl) => {
    setIsUploading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          image: imageDataUrl,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
      });

      // Read the response body only once
      const data = await response.json();

      if (!response.ok) {
        // Handle HTTP errors
        if (response.status === 403) {
          // User has no scans remaining
          alert(data.error || "No scans remaining. Please subscribe.");
          navigate("/#pricing"); // Redirect to pricing or subscription page
        } else {
          throw new Error(
            data.error || "An error occurred while processing the image."
          );
        }
      } else {
        // After successful processing, refresh the profile
        await refreshProfile(); // Fetch updated profile

        // Proceed with handling the data
        if (Array.isArray(data.events) && data.events.length > 0) {
          const updatedEvents = data.events.map((event) => ({
            ...event,
            selected: true,
          }));
          setEvents(updatedEvents);
          setShowModal(true);
        } else {
          toast.error(
            "I'm sorry, I couldn't get any events out of this photo."
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.message);
    } finally {
      setIsUploading(false);
    }
  };

  // Function to delete an event
  const deleteEvent = async (eventId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/delete-event`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: eventId }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to delete the event.");
      }

      // Remove the deleted event from the upcomingEvents state
      setUpcomingEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );

      // Also remove from the ScheduleModal's events if present
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event.id !== eventId)
      );
    } catch (error) {
      console.error("Error deleting event:", error);
      alert(error.message);
    }
  };

  // Handle event changes
  const handleEventChange = (index, key, value) => {
    const updatedEvents = [...events];
    updatedEvents[index][key] = value;
    setEvents(updatedEvents);
  };

  // Handle checkbox toggles
  const handleCheckboxChange = (index) => {
    const updatedEvents = [...events];
    updatedEvents[index].selected = !updatedEvents[index].selected;
    setEvents(updatedEvents);
  };

  // Handle scheduling all selected events
  const handleScheduleAll = async () => {
    const selectedEvents = events.filter((event) => event.selected);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-events`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ events: selectedEvents }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to schedule events.");
      }

      console.log("All events scheduled:", data);

      setShowModal(false); // Close the modal

      // Navigate to Dashboard with the newly scheduled events
      navigate("/dashboard", { state: { newEvents: data.events } });
    } catch (error) {
      console.error("Error scheduling events:", error);
      alert(error.message || "Error scheduling events.");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const HandleCameraButtonClick = () => {
    navigate("/camera");
  };

  // Helper function to determine if an event is highlighted
  const isEventHighlighted = (eventId) => {
    return highlightedEventIds.includes(eventId);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header
        handleLogout={handleLogout}
        showBackButton={true}
        backButtonPath="/dashboard"
      />

      {/* Hidden File Input */}
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
      />

      {/* Content Area */}
      <div className="flex-grow flex flex-col items-center bg-white px-6 py-12">
        {/* Main Buttons */}
        <h3 className="mb-4 flex items-center justify-between">
          <span>
            {profile.is_subscribed
              ? "🌟 Subscribed"
              : `Free Uploads ${profile.scans_remaining}/3`}
          </span>
          {!profile.is_subscribed && (
            <button
              onClick={handleUpgrade}
              className="ml-4 px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline transition"
            >
              Upgrade
            </button>
          )}
        </h3>

        <div className="flex space-x-4 mb-8">
          <button
            onClick={handleUploadPhotoClick}
            className={`px-6 py-3 rounded border transition ${
              profile.scans_remaining === 0 && !profile.is_subscribed
                ? "border-gray-200 bg-gray-100 text-gray-400 cursor-not-allowed"
                : "border-gray-300 hover:bg-gray-100"
            }`}
            // disabled={
            //   (profile.scans_remaining === 0 && !profile.is_subscribed) ||
            //   isUploading
            // }
            disabled={true}
          >
            {isUploading ? "Processing..." : "Upload Photo"}
          </button>
          <button
            onClick={HandleCameraButtonClick}
            className={`px-6 py-3 rounded border transition ${
              profile.scans_remaining === 0 && !profile.is_subscribed
                ? "border-gray-200 bg-gray-100 text-gray-400 cursor-not-allowed"
                : "border-gray-300 hover:bg-gray-100"
            }`}
            // disabled={
            //   (profile.scans_remaining === 0 && !profile.is_subscribed) ||
            //   isUploading ||
            //   isLoadingEvents
            // }
            disabled={true}
          >
            Take a Photo
          </button>
        </div>

        {/* Upcoming Events */}
        <div className="w-full max-w-lg mx-auto">
          <h2 className="text-2xl font-semibold mb-4 text-gray-900">
            Upcoming Events
          </h2>
          {profile.email}
          {isLoadingEvents ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-gray-600"></div>
              <span className="ml-4 text-gray-600">Loading events...</span>
            </div>
          ) : error ? (
            <p className="text-red-500">
              Sorry their's an error. Please try again later or email
              contact@scantoschedule.com
            </p>
          ) : upcomingEvents.length > 0 ? (
            <div className="space-y-6">
              {Object.keys(groupedEvents).map((dayLabel) => (
                <div key={dayLabel}>
                  <h3 className="text-xl font-semibold text-gray-800 mb-4">
                    {dayLabel}
                  </h3>
                  <ul className="space-y-4">
                    {groupedEvents[dayLabel].map((event) => (
                      <li key={event.id}>
                        <a
                          href={event.htmlLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={`relative block p-4 bg-white rounded border border-gray-200 shadow-sm hover:bg-gray-100 transition ${
                            isEventHighlighted(event.id)
                              ? "border-4 border-blue-500"
                              : ""
                          }`}
                        >
                          {/* 'X' Delete Button or Confirm/Cancel Buttons */}
                          {confirmDeleteEventId === event.id ? (
                            <div className="absolute top-2 right-2 space-x-1">
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  deleteEvent(event.id);
                                  setConfirmDeleteEventId(null);
                                }}
                                className="text-red-600 hover:text-red-800 text-sm font-semibold"
                              >
                                Confirm
                              </button>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setConfirmDeleteEventId(null);
                                }}
                                className="text-gray-600 hover:text-gray-800 text-sm font-semibold"
                              >
                                Cancel
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={(e) => {
                                e.preventDefault(); // Prevent navigation
                                e.stopPropagation(); // Prevent event bubbling
                                setConfirmDeleteEventId(event.id);
                              }}
                              className="absolute top-2 right-2"
                              aria-label="Delete Event"
                            >
                              {/* Subtle 'X' Icon using Feather Icons */}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4 text-gray-500 hover:text-gray-700 transition-colors duration-200"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <line
                                  x1="18"
                                  y1="6"
                                  x2="6"
                                  y2="18"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <line
                                  x1="6"
                                  y1="6"
                                  x2="18"
                                  y2="18"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          )}

                          {/* "Just Scheduled" Badge */}
                          {isEventHighlighted(event.id) && (
                            <span className="absolute bottom-2 right-2 bg-blue-500 text-white text-xs font-semibold px-2 py-1 rounded-full">
                              Just Scheduled
                            </span>
                          )}

                          <h4 className="text-gray-700 font-medium">
                            {event.summary}
                          </h4>
                          <p className="text-gray-600 mt-2">
                            {formatTime(
                              event.start.dateTime,
                              event.end.dateTime
                            )}
                          </p>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No upcoming events found.</p>
          )}
        </div>
      </div>

      {/* Side Menu Overlay and Menu */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          {/* Side Menu */}
          <div className="fixed top-0 right-0 w-64 h-full bg-white border-l border-gray-200 z-20 md:hidden">
            <div className="p-4">
              {/* Close Button */}
              <button
                onClick={() => setIsMenuOpen(false)}
                className="focus:outline-none mb-4"
              >
                <svg
                  className="h-6 w-6 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="mt-4 w-full px-6 py-3 rounded border border-gray-300 text-red-600 hover:bg-gray-100 transition"
              >
                Logout
              </button>
            </div>
          </div>
        </>
      )}

      {/* Schedule Modal */}
      {showModal && (
        <ScheduleModal
          events={events}
          handleEventChange={handleEventChange}
          handleCheckboxChange={handleCheckboxChange}
          handleScheduleAll={handleScheduleAll}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default Dashboard;
