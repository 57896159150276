import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto my-12 p-6">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="mb-4">Last updated: October 23, 2024</p>

      <p className="mb-4">
        This Privacy Policy describes how <strong>Scan to Schedule</strong>
        ("we," "us," or "our") collects, uses, retains, and deletes your
        personal information when you use our application (the "Service").
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        1. Information We Collect
      </h2>

      <h3 className="text-xl font-semibold mb-2">1.1 Personal Information</h3>
      <p className="mb-4">
        When you use our Service, we may collect personal information that you
        voluntarily provide to us, including:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Google Account Information:</strong> Your email address,
          profile picture, and basic profile information obtained through Google
          OAuth authentication.
        </li>
        <li>
          <strong>Google Calendar Data:</strong> Access to your Google Calendar
          to create, modify, or manage events based on your input.
        </li>
      </ul>

      <h3 className="text-xl font-semibold mb-2">1.2 Usage Data</h3>
      <p className="mb-4">
        We may collect information on how the Service is accessed and used
        ("Usage Data"). This Usage Data may include information such as your
        device's Internet Protocol address (e.g., IP address), browser type,
        browser version, and other diagnostic data.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. How We Use Your Information
      </h2>

      <p className="mb-4">
        We use the collected information for various purposes:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To provide and maintain the Service.</li>
        <li>To authenticate your access to the Service.</li>
        <li>
          To create and manage calendar events in your Google Calendar based on
          the data you provide.
        </li>
        <li>
          To communicate with you, including responding to your comments,
          questions, and requests.
        </li>
        <li>To monitor the usage of the Service.</li>
        <li>To detect, prevent, and address technical issues.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. Information Sharing and Disclosure
      </h2>

      <p className="mb-4">
        We do not sell, trade, or rent your personal information to third
        parties. We may share your information in the following circumstances:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Service Providers:</strong> We may employ third-party
          companies and individuals to facilitate our Service ("Service
          Providers"), to provide the Service on our behalf, or to assist us in
          analyzing how our Service is used.
        </li>
        <li>
          <strong>Legal Requirements:</strong> We may disclose your personal
          information if required to do so by law or in response to valid
          requests by public authorities.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Data Security</h2>

      <p className="mb-4">
        We are committed to protecting your data. We implement appropriate
        security measures to safeguard your personal information against
        unauthorized access, alteration, disclosure, or destruction.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        5. Data Retention and Deletion
      </h2>

      <p className="mb-4">
        We retain your personal information for as long as it is necessary to
        provide the Service to you and fulfill the purposes described in this
        Privacy Policy. You may request the deletion of your personal data at
        any time by contacting us at{" "}
        <a href="mailto:contact@scantoschedule.com">
          contact@scantoschedule.com
        </a>
        . Upon receiving your request, we will promptly delete your data unless
        we are required by law to retain certain information. Google Calendar
        data is retained only for the duration of your use of the Service and is
        deleted once you revoke our access or discontinue using the Service.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Your Choices and Rights
      </h2>

      <p className="mb-4">
        You may choose to stop using the Service at any time. You can revoke our
        access to your Google Account data by adjusting your Google Account
        permissions. You can also request the deletion of your data as outlined
        in Section 5.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Children's Privacy
      </h2>

      <p className="mb-4">
        Our Service is not intended for use by anyone under the age of 13. We do
        not knowingly collect personally identifiable information from anyone
        under the age of 13. If you are a parent or guardian and you are aware
        that your child has provided us with personal information, please
        contact us.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        8. Changes to This Privacy Policy
      </h2>

      <p className="mb-4">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Last updated" date at the top.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">9. Contact Us</h2>

      <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <p className="mb-4">
        Email:{" "}
        <a href="mailto:contact@scantoschedule.com">
          contact@scantoschedule.com
        </a>
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">10. Consent</h2>

      <p className="mb-4">
        By using our Service, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
