// CameraPage.js
import Header from "./components/Header";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ScheduleModal from "./components/scheduleModal";
import { AuthContext } from "./contexts/AuthProvider";
import { toast } from "react-toastify";
import { useModalContext } from "./contexts/PricingContext";

const CameraPage = ({ handleLogout }) => {
  const { profile, session, refreshProfile } = useContext(AuthContext);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const navigate = useNavigate();
  const { openPricingModal } = useModalContext();

  useEffect(() => {
    if (profile.scans_remaining === 0 && !profile.is_subscribed) {
      navigate("/dashboard", { replace: true }); // Redirect if conditions are not met
    }
  }, [profile, navigate]);

  useEffect(() => {
    let mediaStream = null;

    const startCamera = async () => {
      try {
        mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { ideal: "environment" } },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStream;
        }
      } catch (err) {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          if (videoRef.current) {
            videoRef.current.srcObject = mediaStream;
          }
        } catch (error) {
          console.error("Unable to access camera.");
        }
      }
    };

    startCamera();

    return () => {
      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  // Updated handleTakePhoto function
  const handleTakePhoto = async () => {
    setIsLoading(true); // Start loading
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!canvas || !video) {
      console.error("Canvas or video element not found.");
      setIsLoading(false);
      toast.error("Error accessing camera or canvas. Please try again.");
      return;
    }

    const context = canvas.getContext("2d");

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const imageDataUrl = canvas.toDataURL("image/png");

    // Do not stop the video stream here

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.access_token}`,
        },
        body: JSON.stringify({
          image: imageDataUrl,
          timezone: selectedTimeZone,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (response.status === 403) {
          toast.error(data.error || "No scans remaining. Please subscribe.");
          openPricingModal();
        } else {
          throw new Error(
            data.error || "An error occurred while processing the image."
          );
        }
      } else {
        await refreshProfile();
      }

      setIsLoading(false); // Stop loading

      if (Array.isArray(data.events) && data.events.length > 0) {
        const updatedEvents = data.events.map((event) => ({
          ...event,
          selected: true,
        }));
        setEvents(updatedEvents); // Directly update the state
        setShowModal(true); // Show the modal for user interaction

        // Stop the video stream since we're done with it
        if (video.srcObject) {
          video.srcObject.getTracks().forEach((track) => track.stop());
        }
      } else {
        toast.error("I'm sorry, I couldn't get any events out of this photo.");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false); // Stop loading if there's an error
      toast.error(error.message);
    }
  };

  const handleEventChange = (index, key, value) => {
    const updatedEvents = [...events];
    updatedEvents[index][key] = value;
    setEvents(updatedEvents);
  };

  const handleCheckboxChange = (index) => {
    const updatedEvents = [...events];
    updatedEvents[index].selected = !updatedEvents[index].selected;
    setEvents(updatedEvents);
  };

  // Updated handleScheduleAll function
  const handleScheduleAll = async () => {
    const selectedEvents = events.filter((event) => event.selected);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-events`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ events: selectedEvents }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to schedule events.");
      }

      console.log("All events scheduled:", data);

      setShowModal(false); // Close the modal

      // Navigate to Dashboard with the newly scheduled events
      navigate("/dashboard", { state: { newEvents: data.events } });
    } catch (error) {
      console.error("Error scheduling events:", error);
      alert(error.message || "Error scheduling events.");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <Header
        handleLogout={handleLogout}
        showBackButton={true}
        backButtonPath="/dashboard"
      />

      {/* Content Area */}
      <div className="flex-grow flex items-center justify-center bg-black md:bg-white">
        {/* Video Container */}
        <div
          className="
          w-full
          md:w-auto
          aspect-[9/16]
          md:aspect-[16/9]
          bg-black md:bg-white
          md:rounded-3xl md:border md:shadow-lg
          overflow-hidden flex items-center justify-center
        "
        >
          {/* Video Preview */}
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            className="w-full h-full object-cover"
          />
        </div>
        {/* Take Photo Button */}
        <div className="absolute bottom-0 left-0 right-0 mb-6 flex justify-center">
          <button
            onClick={handleTakePhoto}
            className={`px-6 py-3 rounded-full bg-white bg-opacity-75 backdrop-blur-md border border-gray-300 hover:bg-opacity-100 transition ease-in-out flex items-center justify-center ${
              isLoading ? "cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-5 w-5 border-t-2 border-gray-600 mr-3"></div>
                Processing...
              </>
            ) : (
              "Take Photo"
            )}
          </button>
        </div>

        {/* Loading Indicator */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <p className="text-white text-xl">
              Processing photo, please wait...
            </p>
          </div>
        )}
      </div>

      {/* Hidden Canvas */}
      <canvas ref={canvasRef} className="hidden" />

      {/* Side Menu Overlay and Menu */}
      {isMenuOpen && (
        <>
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 z-10"
            onClick={() => setIsMenuOpen(false)}
          ></div>

          {/* Side Menu */}
          <div className="fixed top-0 right-0 w-64 h-full bg-white border-l border-gray-200 z-20 md:hidden">
            <div className="p-4">
              {/* Close Button */}
              <button
                onClick={() => setIsMenuOpen(false)}
                className="focus:outline-none mb-4"
              >
                <svg
                  className="h-6 w-6 text-gray-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>

              {/* Logout Button */}
              <button
                onClick={handleLogout}
                className="mt-4 w-full px-6 py-3 rounded border border-gray-300 text-red-600 hover:bg-gray-100 transition"
              >
                Logout
              </button>
            </div>
          </div>
        </>
      )}

      {/* Event Modal */}
      {showModal && (
        <ScheduleModal
          events={events}
          handleEventChange={handleEventChange}
          handleCheckboxChange={handleCheckboxChange}
          handleScheduleAll={handleScheduleAll}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default CameraPage;
