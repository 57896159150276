// src/components/TermsOfService.js
import React from "react";

const TermsOfService = () => {
  return (
    <div className="container mx-auto my-12 p-6">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">Last updated: October 19, 2024</p>

      <p className="mb-4">
        Welcome to <strong>Scan to Schedule</strong> ("we," "us," or "our"). By
        accessing or using our application (the "Service"), you agree to be
        bound by these Terms of Service ("Terms"). If you do not agree to these
        Terms, please do not use our Service.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        1. Acceptance of Terms
      </h2>
      <p className="mb-4">
        By creating an account or otherwise using the Service, you confirm that
        you accept these Terms and agree to comply with them.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. Description of Service
      </h2>
      <p className="mb-4">
        <strong>Scan to Schedule</strong> provides a platform that allows users
        to scan documents or images to create events in their Google Calendar.
        The Service integrates with Google APIs to manage calendar events on
        your behalf.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Eligibility</h2>
      <p className="mb-4">
        You must be at least 13 years old to use our Service. By using the
        Service, you represent and warrant that you meet this eligibility
        requirement.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. User Accounts</h2>
      <p className="mb-4">
        When you create an account with us, you must provide accurate and
        complete information. You are responsible for safeguarding your account
        and for all activities that occur under your account.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. User Conduct</h2>
      <p className="mb-4">
        You agree not to use the Service for any unlawful or prohibited
        activities. You must not:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>
          Use the Service in any manner that could disable, overburden, or
          impair the Service.
        </li>
        <li>Upload or transmit any viruses or malicious code.</li>
        <li>Attempt to gain unauthorized access to any part of the Service.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Intellectual Property Rights
      </h2>
      <p className="mb-4">
        All content, trademarks, and data on the Service, including but not
        limited to software, text, graphics, logos, and images, are the property
        of <strong>Scan to Schedule</strong> or its licensors and are protected
        by applicable intellectual property laws.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Third-Party Services
      </h2>
      <p className="mb-4">
        Our Service may contain links to third-party websites or services that
        are not owned or controlled by us. We have no control over and assume no
        responsibility for the content or practices of any third-party websites
        or services.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">8. Termination</h2>
      <p className="mb-4">
        We may terminate or suspend your access to the Service immediately,
        without prior notice or liability, for any reason whatsoever, including
        without limitation if you breach the Terms.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        9. Limitation of Liability
      </h2>
      <p className="mb-4">
        In no event shall <strong>Scan to Schedule</strong>, nor its directors,
        employees, partners, agents, suppliers, or affiliates, be liable for any
        indirect, incidental, special, consequential, or punitive damages
        arising out of your access to or use of the Service.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        10. Disclaimer of Warranties
      </h2>
      <p className="mb-4">
        The Service is provided on an "AS IS" and "AS AVAILABLE" basis without
        warranties of any kind. We do not warrant that the Service will function
        uninterrupted or be error-free.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">11. Governing Law</h2>
      <p className="mb-4">
        These Terms shall be governed and construed in accordance with the laws
        of the United States, without regard to its conflict of law provisions.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">12. Changes to Terms</h2>
      <p className="mb-4">
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. We will notify you of any changes by posting the new
        Terms on this page and updating the "Last updated" date at the top.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">13. Contact Us</h2>
      <p className="mb-4">
        If you have any questions about these Terms, please contact us at:
      </p>
      <p className="mb-4">
        Email:{" "}
        <a href="mailto:contact@scantoschedule.com">
          contact@scantoschedule.com
        </a>
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">14. Entire Agreement</h2>
      <p className="mb-4">
        These Terms constitute the entire agreement between you and{" "}
        <strong>Scan to Schedule</strong> regarding the Service and supersede
        any prior agreements.
      </p>
    </div>
  );
};

export default TermsOfService;
