import React, { useState } from "react";
import scanToScheduleLogo from "./assets/scan-to-schedule-logo.png";
import heroImage from "./assets/hero-scan-to-schedule.png";
import feature1 from "./assets/scan-to-schedule-feature-1.png";
import feature2 from "./assets/scan-to-schedule-feature-2.png";
import feature3 from "./assets/scan-to-schedule-feature-3.png";
import chloeTestimonial from "./assets/chloe-testimonial.png";
import ianTestimonial from "./assets/ian-testimonial.png";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import PricingComponent from "./components/PricingComponent";

const LandingPage = ({ session, handleGoogleLogOut, handleGoogleLogin }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const handleLoginLogout = async () => {
    if (session) {
      handleGoogleLogOut();
    } else {
      const redirectTo = `${window.location.origin}/dashboard`;
      console.log("Redirecting to:", redirectTo);
      await handleGoogleLogin(redirectTo);
    }
  };

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const faqs = [
    {
      question: "How does ScanToSchedule work?",
      answer:
        "Simply snap a picture of your handwritten notes or schedules, and our system will automatically convert it into digital calendar events.",
    },
    {
      question: "Is my data secure?",
      answer:
        "Yes, your data is encrypted and securely stored. We prioritize your privacy and ensure that your data is never shared with third parties.",
    },
    {
      question: "Can I sync with multiple calendars?",
      answer:
        "Currently, ScanToSchedule allows you to sync with Google Calendar though other popular calendar apps will be added soon.",
    },
    {
      question: "How do I cancel my subscription?",
      answer:
        "You can cancel your subscription at any time through the settings page in your account dashboard.",
    },
  ];

  return (
    <div className="min-h-screen flex flex-col">
      {/* Header */}
      <header className="flex items-center justify-between px-6 py-4 bg-white border-b fixed top-0 w-full z-10">
        {/* Logo and Title */}
        <div className="flex items-center">
          <img
            src={scanToScheduleLogo}
            alt="Scan to Schedule Logo"
            className="w-8 h-8 mr-2"
          />
          <span className="text-xl font-medium text-gray-900">
            ScanToSchedule
          </span>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden md:flex space-x-6">
          {session && (
            <a
              href="/dashboard"
              className="text-gray-600 hover:underline cursor-pointer"
            >
              Dashboard
            </a>
          )}
          <a
            href="#features"
            className="text-gray-600 hover:underline cursor-pointer"
          >
            Features
          </a>
          <a
            href="#pricing"
            className="text-gray-600 hover:underline cursor-pointer"
          >
            Pricing
          </a>
          <a
            href="#testimonials"
            className="text-gray-600 hover:underline cursor-pointer"
          >
            Testimonials
          </a>
          <a
            onClick={handleLoginLogout}
            className="text-gray-600 hover:underline cursor-pointer"
          >
            {session ? "Logout" : "Login"}
          </a>
        </nav>

        {/* Hamburger Menu for Mobile */}
        <nav className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
          >
            {isMenuOpen ? (
              <XIcon className="h-6 w-6 text-gray-700" />
            ) : (
              <MenuIcon className="h-6 w-6 text-gray-700" />
            )}
          </button>
          {/* Mobile Menu Items */}
          <ul
            className={`${
              isMenuOpen ? "block" : "hidden"
            } absolute right-4 top-16 bg-white border rounded py-2 w-40`}
          >
            {session && (
              <li>
                <a
                  href="/dashboard"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={() => setIsMenuOpen(false)}
                >
                  Dashboard
                </a>
              </li>
            )}
            <li>
              <a
                href="#features"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={() => setIsMenuOpen(false)}
              >
                Features
              </a>
            </li>
            <li>
              <a
                href="#pricing"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={() => setIsMenuOpen(false)}
              >
                Pricing
              </a>
            </li>
            <li>
              <a
                href="#testimonials"
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                onClick={() => setIsMenuOpen(false)}
              >
                Testimonials
              </a>
            </li>
            <li>
              <a
                onClick={handleLoginLogout}
                className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
              >
                {session ? "Logout" : "Login"}
              </a>
            </li>
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <main className="flex-grow bg-white pt-20">
        <div className="container mx-auto flex flex-col-reverse md:flex-row items-center px-6 py-12">
          {/* Text Content */}
          <div className="w-full md:w-1/2">
            <h1 className="text-4xl md:text-5xl font-semibold text-gray-900 mb-6 leading-tight">
              Turn Handwritten Schedules into <br /> Google Calendar Events
            </h1>
            <p className="text-gray-600 mb-8">
              Tired of manually entering your schedule? Snap a photo and let
              ScanToSchedule handle the rest. Seamlessly integrate your
              handwritten notes into Google Calendar.
            </p>
            <div className="flex justify-center">
              <button
                onClick={handleLoginLogout}
                className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100 flex items-center justify-center"
              >
                <img
                  src="https://developers.google.com/identity/images/g-logo.png"
                  alt="Google logo"
                  className="mr-3"
                  width="24"
                  height="24"
                />
                Get Started with Google
              </button>
            </div>
          </div>
          {/* Hero Image */}
          <div className="w-full md:w-1/2 mb-12 md:mb-0">
            <img
              src={heroImage}
              alt="Illustration of scanning schedules"
              className="w-full h-auto"
            />
          </div>
        </div>

        {/* Features Section */}
        <section id="features" className="py-12">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
              Features
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Feature Item */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 rounded border border-gray-200 bg-white">
                  <img
                    src={feature1}
                    alt="Feature 1"
                    className="w-full object-cover mb-4 rounded"
                  />
                  <h3 className="text-xl font-medium mb-2">Easy to Use</h3>
                  <p className="text-gray-600">
                    Simply snap a photo of your schedule, and we'll handle the
                    rest.
                  </p>
                </div>
              </div>
              {/* Feature Item */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 rounded border border-gray-200 bg-white">
                  <img
                    src={feature2}
                    alt="Feature 2"
                    className="w-full object-cover mb-4 rounded"
                  />
                  <h3 className="text-xl font-medium mb-2">
                    Seamless Integration
                  </h3>
                  <p className="text-gray-600">
                    Automatically syncs with your Google Calendar on all
                    devices.
                  </p>
                </div>
              </div>
              {/* Feature Item */}
              <div className="w-full md:w-1/3 px-4 mb-8">
                <div className="p-6 rounded border border-gray-200 bg-white">
                  <img
                    src={feature3}
                    alt="Feature 3"
                    className="w-full object-cover mb-4 rounded"
                  />
                  <h3 className="text-xl font-medium mb-2">
                    Time-Saving Efficiency
                  </h3>
                  <p className="text-gray-600">
                    Spend less time scheduling and more time focused on what
                    matters.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <PricingComponent
          session={session}
          handleGoogleLogin={handleGoogleLogin}
        ></PricingComponent>

        {/* Testimonials Section */}
        <section id="testimonials" className="py-12">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
              Testimonials
            </h2>
            <div className="flex flex-wrap -mx-4">
              {/* Testimonial Item */}
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="p-6 rounded border border-gray-200 flex items-center bg-white">
                  <img
                    src={chloeTestimonial}
                    alt="Chloe"
                    className="w-16 h-16 rounded-full mr-4 object-cover"
                  />
                  <div>
                    <p className="text-gray-600 mb-4">
                      "ScanToSchedule has totally changed the way I organize my
                      schedule. It's quick, efficient, and incredibly easy to
                      use!"
                    </p>
                    <h3 className="text-xl font-medium">- Chloe</h3>
                  </div>
                </div>
              </div>
              {/* Testimonial Item */}
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="p-6 rounded border border-gray-200 flex items-center bg-white">
                  <img
                    src={ianTestimonial}
                    alt="Ian"
                    className="w-16 h-16 rounded-full mr-4 object-cover"
                  />
                  <div>
                    <p className="text-gray-600 mb-4">
                      "An absolute game-changer for managing my workflow. I like
                      to write my plans, but it's a pain to enter it all back
                      into a calendar. This solves that. Highly recommended!"
                    </p>
                    <h3 className="text-xl font-medium">- Ian</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="py-12">
          <div className="container mx-auto px-6 max-w-2xl">
            <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
              Frequently Asked Questions
            </h2>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div
                  key={index}
                  className="bg-white p-6 rounded border border-gray-200 cursor-pointer"
                  onClick={() => toggleAccordion(index)}
                >
                  <h3 className="text-xl font-medium mb-2 text-gray-900 flex justify-between items-center">
                    {faq.question}
                    <span>{activeIndex === index ? "-" : "+"}</span>
                  </h3>
                  {activeIndex === index && (
                    <p className="text-gray-600 mt-4">{faq.answer}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-12">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
              Contact Us
            </h2>
            <p className="text-center text-gray-600 mb-8">
              Have questions? We're here to help!
            </p>
            <div className="flex justify-center">
              <a
                href="mailto:contact@scantoschedule.com"
                className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
              >
                Email Us
              </a>
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-white py-6 border-t">
        <div className="container mx-auto text-center text-gray-600">
          <p>
            By signing in, you agree to our{" "}
            <a href="/privacy" className="text-blue-600 underline">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href="/terms" className="text-blue-600 underline">
              Terms of Service
            </a>
            .
          </p>
          <p className="mt-4">
            For inquiries, contact us at{" "}
            <a
              href="mailto:contact@scantoschedule.com"
              className="text-blue-600 underline"
            >
              contact@scantoschedule.com
            </a>
            .
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
