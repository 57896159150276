import { loadStripe } from "@stripe/stripe-js";
// import React, { useContext } from 'react';
// import { AuthContext } from './contexts/AuthProvider';
// Initialize Stripe with environment variable
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PricingSection = ({ session, handleGoogleLogin }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleCheckout = async (priceId) => {
    if (!session) {
      // User is not logged in
      const redirectTo = `${window.location.origin}/?priceId=${priceId}`;
      await handleGoogleLogin(redirectTo);
      return;
    }

    // User is logged in, proceed with checkout
    const stripe = await stripePromise;
    const accessToken = session.access_token;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/stripe-webhook`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ priceId }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        // Redirect to Stripe Checkout
        window.location.href = data.url;
      } else {
        throw new Error(data.error || "Failed to create checkout session.");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  // Handle Post-Login Checkout
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const priceId = params.get("priceId");

    if (session && priceId) {
      // Remove priceId from URL to prevent repeated execution
      navigate(location.pathname, { replace: true });
      handleCheckout(priceId);
    }
  }, [session, location.search]);

  return (
    <>
      <section id="pricing" className="py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
            Pricing
          </h2>
          {/* Pricing Plans */}
          <div className="flex flex-wrap justify-center -mx-4">
            {/* Monthly Plan */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="p-8 rounded border border-gray-200 bg-white text-center">
                <h3 className="text-2xl font-medium mb-4">Monthly</h3>
                <p className="text-gray-600 mb-6">
                  Get started with the essentials to efficiently manage your
                  schedule.
                </p>
                <p className="text-5xl font-bold text-gray-900 mb-6">
                  $5<span className="text-xl font-normal">/month</span>
                </p>
                <ul className="text-gray-600 mb-6 space-y-2">
                  <li>Unlimited access to scheduling features</li>
                  <li>Sync with Google Calendar</li>
                  <li>Basic support via email</li>
                </ul>
                <button
                  onClick={() =>
                    handleCheckout(process.env.REACT_APP_MONTHLY_SUBSCRIPTION)
                  }
                  className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Get Started
                </button>
              </div>
            </div>

            {/* Annual Plan */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="p-8 rounded border border-gray-200 bg-white text-center">
                <h3 className="text-2xl font-medium mb-4">Annual</h3>
                <p className="text-gray-600 mb-6">
                  Save more with the annual plan and enjoy uninterrupted
                  service.
                </p>
                <p className="text-5xl font-bold text-gray-900 mb-6">
                  $40<span className="text-xl font-normal">/year</span>
                </p>
                <ul className="text-gray-600 mb-6 space-y-2">
                  <li>Unlimited access to scheduling features</li>
                  <li>Sync with Google Calendar</li>
                  <li>Priority email support</li>
                  <li>Save 33% with the annual plan</li>
                </ul>
                <button
                  onClick={() =>
                    handleCheckout(process.env.REACT_APP_ANNUAL_SUBSCRIPTION)
                  }
                  className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingSection;
