import React, { useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Success from "./Success";
import Cancel from "./Cancel";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import LandingPage from "./LandingPage";
import Dashboard from "./Dashboard";
import CameraComponent from "./CameraPage";
import ProtectedRoute from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";
import { AuthContext } from "./contexts/AuthProvider";
import supabase from "./supabaseClient";

function App() {
  const navigate = useNavigate();
  const { session } = useContext(AuthContext);

  const signInWithGoogle = async (redirectTo) => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo,
      },
    });
    if (error) {
      console.error("Error signing in with Google:", error.message);
    }
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error signing out:", error.message);
    } else {
      navigate("/");
    }
  };

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path="/"
        element={
          <LandingPage
            session={session}
            handleGoogleLogOut={signOut}
            handleGoogleLogin={signInWithGoogle}
          />
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />

      {/* Protected Routes */}
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard handleLogout={signOut} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/camera"
        element={
          <ProtectedRoute>
            <ErrorBoundary>
              <CameraComponent handleLogout={signOut} />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
      <Route path="/success" element={<Success navigate={navigate} />} />
      <Route path="/cancel" element={<Cancel navigate={navigate} />} />
    </Routes>
  );
}

export default App;
