// Success.js
import React from "react";

const Success = ({ navigate }) => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Subscription Successful!</h1>
        <p className="text-gray-700">
          Thank you for subscribing to ScantoSchedule.
        </p>
        <button
          onClick={() => navigate("/dashboard")}
          className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100 transition"
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default Success;
