// ScheduleModal.js
import React from "react";

const ScheduleModal = ({
  events,
  handleEventChange,
  handleCheckboxChange,
  handleScheduleAll,
  handleCancel,
}) => {
  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-2xl border border-gray-200 max-w-lg w-full relative">
        <h2 className="text-xl font-semibold mb-6 text-center text-gray-900">
          Edit Events
        </h2>
        <div className="max-h-96 overflow-y-auto px-2 space-y-6">
          {events.map((event, index) => {
            const startDateTime = event.start?.dateTime || "";
            const endDateTime = event.end?.dateTime || "";

            return (
              <div
                key={index}
                className="p-4 border border-gray-200 rounded-xl"
              >
                <div className="flex justify-between items-center mb-4">
                  <label className="block text-sm font-medium text-gray-900">
                    Event {index + 1}
                  </label>
                  <input
                    type="checkbox"
                    checked={event.selected}
                    onChange={() => handleCheckboxChange(index)}
                    className="h-5 w-5 accent-blue-500 rounded focus:ring focus:ring-blue-200"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-600">
                    Summary
                  </label>
                  <input
                    type="text"
                    value={event.summary}
                    onChange={(e) =>
                      handleEventChange(index, "summary", e.target.value)
                    }
                    className="w-full p-3 border border-gray-300 rounded-lg text-sm focus:ring focus:ring-blue-200 transition"
                    disabled={!event.selected}
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      Start
                    </label>
                    <input
                      type="datetime-local"
                      value={startDateTime}
                      onChange={(e) =>
                        handleEventChange(index, "start", {
                          ...event.start,
                          dateTime: e.target.value,
                        })
                      }
                      className="w-full p-3 border border-gray-300 rounded-lg text-sm focus:ring focus:ring-blue-200 transition"
                      disabled={!event.selected}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-600">
                      End
                    </label>
                    <input
                      type="datetime-local"
                      value={endDateTime}
                      onChange={(e) =>
                        handleEventChange(index, "end", {
                          ...event.end,
                          dateTime: e.target.value,
                        })
                      }
                      className="w-full p-3 border border-gray-300 rounded-lg text-sm focus:ring focus:ring-blue-200 transition"
                      disabled={!event.selected}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end mt-6 space-x-3">
          <button
            onClick={handleScheduleAll}
            className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
          >
            Schedule All
          </button>
          <button
            onClick={handleCancel}
            className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModal;
